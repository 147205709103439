import * as React from "react";

import CustomRangePicker from "~/components/core/datepickers/CustomRangePicker";
import FilterToolbar from "~/components/core/FilterToolbar";
import { PERIOD } from "~/constants/dates";
import { Period, Range } from "~/declarations/filters";

import DashboardContext from "../DashboardContext";
import { useDashboardQueryState } from "../dashboardQueryState";
import DashboardCustomFilters from "./DashboardCustomFilters";

interface Props {
  periods: Period[];
  // onChangeScale: (scale: string) => void;
  // onChangeSessionType: (sessionType: string) => void;
  // onChangeSubjects: (subject: number[] | null) => void;
  // onChangeRange: (period: Period, range: Range) => void;
  rangeFilter?: Range;
}

function DashboardFilterToolbar(
  {
    periods,
    // onChangeScale,
    // onChangeSessionType,
    // onChangeRange,
    // onChangeSubjects,
    rangeFilter
  }: Props,
  ref: React.Ref<HTMLDivElement>
): JSX.Element {
  const [, setQueryState] = useDashboardQueryState();

  const { selectedPeriod } = React.useContext(DashboardContext);

  const handleChangePeriod = React.useCallback(
    (period: Period, range: Range) => {
      if (period.period === PERIOD.custom) {
        setQueryState({
          period: period.period,
          fromDt: range[0],
          tillDt: range[1]
        });
      } else {
        setQueryState({
          period: period.period,
          fromDt: null,
          tillDt: null
        });
      }
    },
    [setQueryState]
  );

  return (
    <FilterToolbar
      ref={ref}
      periods={periods}
      CustomRangePicker={
        CustomRangePicker as React.FC<{
          onSubmit: (range: Range) => void;
          initialRange?: Range;
        }>
      }
      selectedTab={selectedPeriod?.period || PERIOD.lastMonth}
      rangeFilter={rangeFilter}
      onChange={handleChangePeriod}
    >
      <DashboardCustomFilters
      // onChangeScale={onChangeScale}
      // onChangeSessionType={onChangeSessionType}
      // onChangeSubjects={onChangeSubjects}
      />
    </FilterToolbar>
  );
}

export default React.forwardRef(DashboardFilterToolbar);
