import React from "react";

import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import { useAuthenticatedFetch } from "~/utils/http";

import { DashboardAreaChart } from "../AreaChart";
import { createDashboardLazyWidget } from "../dashboardQueryState";

const DashboardWritingLabPapersCompletedChart = createDashboardLazyWidget({
  displayName: "DashboardWritingLabPapersCompletedChart",
  contentHeight: 320,
  caption: "Papers Completed",
  clarification:
    "Number of papers reviewed by tutors during the time frame selected.",
  useRender: apiQuery => {
    const { data, loading, error } = useAuthenticatedFetch(
      "/api/v1/charts/wl_sessions/",
      apiQuery,
      true
    );

    return (
      <WidgetContentNew
        loading={loading}
        hasData={!!data?.length}
        error={error}
      >
        <DashboardAreaChart apiQuery={apiQuery} data={data} label="Papers" />
      </WidgetContentNew>
    );
  }
});

export default DashboardWritingLabPapersCompletedChart;
