import * as React from "react";

import DropDownFilter from "~/components/core/DropDownFilter";
import styled from "~/components/core/styled";
import SubjectSelect from "~/components/core/SubjectSelect";
import AccountLayoutContext from "~/components/layouts/AccountLayout/AccountLayoutContext";
import {
  ALL_SUBJECTS,
  DropDownFilterItem,
  INIT_SUBJECT_VARIANTS,
  SCALE_VARIANTS,
  scaleFromString,
  SESSION_VARIANTS,
  sessionFromString
} from "~/constants/filters";
import { ISubject, SubjectGroup } from "~/declarations/models/Subject";

import DashboardContext from "../DashboardContext";
import { useDashboardQueryState } from "../dashboardQueryState";

const DashboardCustomFilters: React.FC = () => {
  const [, setQueryState] = useDashboardQueryState();

  const { subjects: allSubjects } = React.useContext(AccountLayoutContext);
  const {
    filter: { subject, scale, lesson },
    restrictedScales
  } = React.useContext(DashboardContext);

  const [subjectsToSelect, setSubjectsToSelect] = React.useState<
    SubjectGroup[] | undefined
  >();

  React.useEffect(() => {
    if (allSubjects.data) {
      setSubjectsToSelect([
        ...INIT_SUBJECT_VARIANTS,
        ...(allSubjects.data ?? [])
      ]);
    }
  }, [allSubjects]);

  const handleChangeScale = React.useCallback(
    (value: DropDownFilterItem) => {
      setQueryState({
        scale: scaleFromString(value.value)
      });
    },
    [setQueryState]
  );

  const handleChangeSessionType = React.useCallback(
    (value: DropDownFilterItem) => {
      setQueryState({
        moderation: sessionFromString(value.value)
      });
    },
    [setQueryState]
  );

  const handleSelectSubject = React.useCallback(
    (selectedSubjects: ISubject[]) => {
      setQueryState({
        subject: selectedSubjects.length ? selectedSubjects[0].id : null
      });
    },
    [setQueryState]
  );

  const selectedSession = SESSION_VARIANTS.find(x => x.value === lesson);
  if (!selectedSession) {
    throw new Error(`invalid session ${lesson}`);
  }

  const selectedScale = SCALE_VARIANTS.find(x => x.value === scale);
  if (!selectedScale) {
    throw new Error(`invalid scale ${scale}`);
  }

  const handleSelectGroup = React.useCallback(
    (subjectGroup: ISubject) => {
      if (subjectGroup.name === ALL_SUBJECTS) {
        setQueryState({
          subject: null
        });
      }
    },
    [setQueryState]
  );

  return (
    <Wrapper>
      <DropDownFilter
        onChange={handleChangeScale}
        items={SCALE_VARIANTS}
        disabledValues={restrictedScales}
        selectedItem={selectedScale}
      />
      <DropDownFilter
        onChange={handleChangeSessionType}
        items={SESSION_VARIANTS}
        selectedItem={selectedSession}
      />
      <SubjectSelect
        initialSubjects={(subject && [subject]) || null}
        subjects={subjectsToSelect}
        loading={allSubjects.loading}
        onSelect={handleSelectSubject}
        onSelectGroup={handleSelectGroup}
      />
    </Wrapper>
  );
};

export default DashboardCustomFilters;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: right;

  > .MuiButtonBase-root {
    margin-right: 10px;
  }

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.sm}px) {
    flex-direction: column;
    height: 130px;
    justify-content: space-between;
    align-items: flex-start;

    > .MuiButtonBase-root {
      margin-right: 0;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    > div {
      width: 100%;
    }
  }
`;
