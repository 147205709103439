import React from "react";

import Legend, { LegendDataModel } from "~/components/core/Legend/Legend";
import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import { theme } from "~/theme/theme";
import { useAuthenticatedFetch } from "~/utils/http";

import { DashboardAreaChart } from "../AreaChart";
import { createDashboardLazyWidget } from "../dashboardQueryState";

const legendData: LegendDataModel[] = [
  { color: theme.palette.data.turquoise[900], text: "LIVE TUTORING" },
  { color: theme.palette.data.blue[900], text: "WRITING LAB" }
];

const DashboardLiveVsWritingLabChart = createDashboardLazyWidget({
  displayName: "DashboardLiveVsWritingLabChart",
  contentHeight: 320,
  caption: "Live Lessons vs Writing Labs",
  clarification:
    "Time spent on Live Tutoring lessons compared to Writing Lab reviews.",
  headerEndAdornment: <Legend legendData={legendData} />,
  useRender: apiQuery => {
    const { data, loading, error } = useAuthenticatedFetch(
      "/api/v1/charts/comparison_sessions/",
      apiQuery,
      true
    );

    return (
      <WidgetContentNew
        loading={loading}
        hasData={!!data?.length}
        error={error}
      >
        <DashboardAreaChart
          apiQuery={apiQuery}
          data={data}
          label="Live Lessons,Writing Labs"
        />
      </WidgetContentNew>
    );
  }
});

export default DashboardLiveVsWritingLabChart;
