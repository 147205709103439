import { omit } from "ramda";
import * as React from "react";

import Rating from "~/components/core/Rating/Rating";
import styled from "~/components/core/styled";
import Table, { Align, TableCell, TableRow } from "~/components/core/Table";
import WidgetCard from "~/components/core/WidgetCard";
import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import { PowerUserHours } from "~/declarations/models/PowerUser";
import { useAuthenticatedFetch } from "~/utils/http";

import { createDashboardLazyWidget } from "../dashboardQueryState";

const COLUMNS = [
  { name: "Student", align: Align.left },
  { name: "Hours", align: Align.center },
  { name: "Avg. Rating", align: Align.left }
];

const StyledTable = styled(Table)`
  width: 100%;
`;

const StyledLazyWidgetCard = styled(WidgetCard)`
  flex: 1;
  margin-left: 18px;
`;

const StyledCell = styled(TableCell)`
  width: 150px;
`;

const DashboardPowerUsersHours = createDashboardLazyWidget({
  displayName: "DashboardPowerUsersHours",
  caption: "Power Users - Hours",
  clarification:
    "A power user is a student who has used the most hours for both Live Tutoring lessons and Writing Lab reviews during the time frame selected.",
  contentHeight: 380,
  customWidgetCard: StyledLazyWidgetCard,
  useRender: apiQuery => {
    const { data, loading, error } = useAuthenticatedFetch(
      "/api/v1/new_stats/combined/power_hours/",
      omit(["scale"], apiQuery),
      true
    );
    return (
      <WidgetContentNew
        loading={loading}
        hasData={!!data?.length}
        error={error}
      >
        <StyledTable columns={COLUMNS} loading={false}>
          {data?.map((user: PowerUserHours) => (
            <TableRow key={user.userId}>
              <TableCell align={Align.left}>{user.displayName}</TableCell>
              <TableCell align={Align.center}>
                {user.hoursTaken.toFixed(1)}
              </TableCell>
              <StyledCell align={Align.left}>
                <Rating rating={user.averageStudentRating} />
              </StyledCell>
            </TableRow>
          ))}
        </StyledTable>
      </WidgetContentNew>
    );
  }
});

export default DashboardPowerUsersHours;
