import * as React from "react";

import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import { useAuthenticatedFetch } from "~/utils/http";

import { DashboardAreaChart } from "../AreaChart";
import { createDashboardLazyWidget } from "../dashboardQueryState";

const DashboardTotalHoursChart = createDashboardLazyWidget({
  displayName: "DashboardTotalHoursChart",
  contentHeight: 320,
  caption: "Total Hours",
  clarification:
    "Total combined hours for both Live Tutoring lessons and Writing Lab reviews.",
  useRender: apiQuery => {
    const { data, loading, error } = useAuthenticatedFetch(
      "/api/v1/charts/combined_hours/",
      apiQuery,
      true
    );

    return (
      <WidgetContentNew
        loading={loading}
        hasData={!!data?.length}
        error={error}
      >
        <DashboardAreaChart apiQuery={apiQuery} data={data} label="Hours" />
      </WidgetContentNew>
    );
  }
});

export default DashboardTotalHoursChart;
