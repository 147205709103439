import * as React from "react";

import AnchorIntersectionObserver from "~/components/core/AnchorIntersectionObserver";
import SectionTitle from "~/components/core/SectionTitle";
import styled from "~/components/core/styled";
import { PERIOD, PERIODS } from "~/constants/dates";
import { RANGE_GETTERS } from "~/constants/filters";
import { Range } from "~/declarations/filters";

import { useRequiredAuthContext } from "../auth/AuthWrapper";
import DashboardActiveStudentsChart from "./DashboardActiveStudentsChart";
import DashboardContext from "./DashboardContext";
import DashboardFilteredStatistic from "./DashboardFilteredStatistic";
import DashboardFilterToolbar from "./DashboardFilterToolbar";
import DashboardGuide from "./DashboardGuide";
import DashboardGuideContext from "./DashboardGuide/DashboardGuideContext";
import DashboardHeader from "./DashboardHeader";
import DashboardLiveTutoringStatistic from "./DashboardLiveTutoringStatistic";
import DashboardLiveTutoringTotalCompletedChart from "./DashboardLiveTutoringTotalCompletedChart";
import DashboardLiveTutoringTotalHoursChart from "./DashboardLiveTutoringTotalHoursChart";
import DashboardLiveVsWritingLabChart from "./DashboardLiveVsWritingLabChart";
// import DashboardMobileVsDesktopChart from "./DashboardMobileVsDesktopChart";
import DashboardPowerUsersHours from "./DashboardPowerUsers/DashboardPowerUsersHours";
import DashboardPowerUsersSessions from "./DashboardPowerUsers/DashboardPowerUsersSessions";
import { useDashboardQueryState } from "./dashboardQueryState";
import DashboardTotalHoursChart from "./DashboardTotalHoursChart";
import DashboardTotalSessionsChart from "./DashboardTotalSessionsChart";
import DashboardWritingLabHoursChart from "./DashboardWritingLabHoursChart";
import DashboardWritingLabPapersCompletedChart from "./DashboardWritingLabPapersCompletedChart";
import DashboardWritingLabStatistic from "./DashboardWritingLabStatistic";
import useScaleRestrictions from "./useScaleRestrictions";

const Dashboard: React.FC = (): JSX.Element => {
  const headerRef = React.useRef<HTMLDivElement | null>(null);
  const filtersRef = React.useRef<HTMLDivElement | null>(null);
  const generalReportsRef = React.useRef<HTMLDivElement | null>(null);
  const liveLessonsRef = React.useRef<HTMLDivElement | null>(null);
  const writingLabRef = React.useRef<HTMLDivElement | null>(null);

  const labsStatistic = React.useRef<HTMLDivElement | null>(null);
  const hoursUsed = React.useRef<HTMLDivElement | null>(null);
  const papersCompleted = React.useRef<HTMLDivElement | null>(null);
  const liveLessons = React.useRef<HTMLDivElement | null>(null);
  const liveLessonsStat = React.useRef<HTMLDivElement | null>(null);
  const lessonHours = React.useRef<HTMLDivElement | null>(null);
  const totalLessons = React.useRef<HTMLDivElement | null>(null);
  // const mobileLessons = React.useRef<HTMLDivElement | null>(null);
  const filteredStatistic = React.useRef<HTMLDivElement | null>(null);
  const hoursUsedSessions = React.useRef<HTMLDivElement | null>(null);
  const sessionsCompleted = React.useRef<HTMLDivElement | null>(null);
  const activeStudents = React.useRef<HTMLDivElement | null>(null);
  const powerUsers = React.useRef<HTMLDivElement | null>(null);

  const [
    {
      fromDt: fromDtFilter,
      tillDt: tillDtFilter,
      subject: subjectFilter,
      moderation: lessonFilter,
      period: periodFilter,
      scale: scaleFilter
    }
  ] = useDashboardQueryState();

  const rangeFilter: Range =
    periodFilter === PERIOD.custom
      ? [fromDtFilter, tillDtFilter]
      : RANGE_GETTERS[periodFilter]();
  const selectedPeriod = PERIODS.find(({ period }) => period === periodFilter);

  if (!selectedPeriod) {
    console.error(`unknown period ${periodFilter}`);
    throw new Error(`unknown period ${periodFilter}`);
  }

  const restrictedScales = useScaleRestrictions(rangeFilter);

  const {
    selectedUmbrella: {
      umbrellaAccName,
      billingMode,
      timeLimitMinutes: limitMinutes,
      expirationDate: dateExpires
    }
  } = useRequiredAuthContext();

  return (
    <DashboardContext.Provider
      key={umbrellaAccName}
      value={{
        filter: {
          fromDt: rangeFilter[0]?.toISOString(),
          tillDt: rangeFilter[1]?.toISOString(),
          scale: scaleFilter,
          lesson: lessonFilter,
          subject: subjectFilter ?? undefined
        },
        selectedPeriod,
        umbrellaLimits: {
          billingMode,
          minutes: limitMinutes,
          expires: dateExpires
        },
        explicit: true,
        restrictedScales
      }}
    >
      <DashboardGuideContext.Provider
        value={{
          headerRef,
          filtersRef,
          generalReportsRef,
          liveLessonsRef,
          writingLabRef
        }}
      >
        <AnchorIntersectionObserver
          refs={[
            { id: "", ref: headerRef, forceOffset: 0 },
            { id: "labsStatistic", ref: labsStatistic },
            { id: "hoursUsed", ref: hoursUsed },
            { id: "papersCompleted", ref: papersCompleted },
            { id: "liveLessons", ref: liveLessons },
            { id: "liveLessonsStat", ref: liveLessonsStat },
            { id: "lessonHours", ref: lessonHours },
            { id: "totalLessons", ref: totalLessons },
            // { id: "mobileLessons", ref: mobileLessons },
            { id: "filteredStatistic", ref: filteredStatistic },
            { id: "hoursUsedSessions", ref: hoursUsedSessions },
            { id: "sessionsCompleted", ref: sessionsCompleted },
            { id: "activeStudents", ref: activeStudents },
            { id: "powerUsers", ref: powerUsers }
          ]}
        />
        <DashboardGuide />
        <div id="header" ref={headerRef} />
        <DashboardHeader />
        <DashboardFilterToolbar
          ref={filtersRef}
          periods={PERIODS}
          rangeFilter={rangeFilter}
        />
        <Content>
          {!subjectFilter && (
            <>
              <SectionTitle
                title="General Reports"
                subtitle="These are the stats for both Live Tutoring lessons and Writing Lab reviews. To see separate reports, scroll down."
              />
              <div id="filteredStatistic" ref={filteredStatistic} />
              <DashboardFilteredStatistic />
              <div id="hoursUsedSessions" ref={hoursUsedSessions} />
              <DashboardTotalHoursChart />
              <div id="sessionsCompleted" ref={sessionsCompleted} />
              <DashboardTotalSessionsChart />
              <div id="activeStudents" ref={activeStudents} />
              <DashboardActiveStudentsChart />
              <div id="powerUsers" ref={powerUsers} />
              <PowerUsersBlock>
                <DashboardPowerUsersSessions />
                <DashboardPowerUsersHours />
              </PowerUsersBlock>
            </>
          )}

          <div ref={liveLessonsRef} />
          <SectionTitle
            title="Live Tutoring"
            subtitle="These numbers are for Live Tutoring lessons only."
          />
          <div id="liveLessonsStat" ref={liveLessonsStat} />
          <DashboardLiveTutoringStatistic />
          <div id="lessonHours" ref={lessonHours} />
          <DashboardLiveTutoringTotalHoursChart />
          <div id="totalLessons" ref={totalLessons} />
          <DashboardLiveTutoringTotalCompletedChart />
          {/* <div id="mobileLessons" ref={mobileLessons} />
            <DashboardMobileVsDesktopChart /> */}

          {!subjectFilter && (
            <>
              <div ref={writingLabRef} />
              <SectionTitle
                title="Writing Lab"
                subtitle="These numbers are for Writing Lab reviews only."
              />
              <div id="labsStatistic" ref={labsStatistic} />
              <DashboardWritingLabStatistic />
              <div id="hoursUsed" ref={hoursUsed} />
              <DashboardWritingLabHoursChart />
              <div id="papersCompleted" ref={papersCompleted} />
              <DashboardWritingLabPapersCompletedChart />
              <div id="liveLessons" ref={liveLessons} />
              <DashboardLiveVsWritingLabChart />
            </>
          )}
        </Content>
      </DashboardGuideContext.Provider>
    </DashboardContext.Provider>
  );
};

export default Dashboard;

const Content = styled.article`
  flex-grow: 1;
  height: 100%;
`;

const PowerUsersBlock = styled.div`
  display: flex;

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.sm}px) {
    flex-direction: column;
  }
`;
