import * as React from "react";

import AverageRatingIcon from "~/assets/icons/AverageRating.svg?sprite";
import HourglassIcon from "~/assets/icons/Hourglass.svg?sprite";
import TimeIcon from "~/assets/icons/Time.svg?sprite";
import {
  ApiStatisticCard,
  statisticsProvider,
} from "~/components/core/StatisticCard/StatisticCard";
import StatisticCardSkeleton from "~/components/core/StatisticCard/StatisticCardSkeleton";
import styled from "~/components/core/styled";
import { SECONDS_IN_MINUTE } from "~/constants/dates";

import { LazyDashboardComponent } from "../dashboardQueryState";
import { StatisticsWrapper } from "../styled";

const STUB_VALUE = "-";

function formatMins<Type>(value?: Type) {
  let avgLessonLength = STUB_VALUE;
  const numberValue = value as unknown as number;

  if (value && Number.isFinite(numberValue)) {
    avgLessonLength = (numberValue / SECONDS_IN_MINUTE).toFixed(0);
  }

  return `${avgLessonLength} mins`;
}

export function averageStudentRating<Type>(value?: Type) {
  let avgRating = STUB_VALUE;
  const numberValue = value as unknown as number;

  if (value && Number.isFinite(numberValue)) {
    avgRating = numberValue.toFixed(2);
  }

  return (
    <AverageStudentRating>
      {avgRating}
      <OutOf>out of</OutOf> 5
    </AverageStudentRating>
  );
}

const DashboardLiveTutoringStatistic: React.FC = () => {
  return (
    <StatisticsWrapper>
      <LazyDashboardComponent
        intersecionDebugName="live: Avg. lessons length"
        placeholder={<StatisticCardSkeleton />}
        render={(apiQuery, ref) => (
          <ApiStatisticCard
            ref={ref}
            provider={statisticsProvider(
              "/api/v1/new_stats/live/average_lesson_length/",
              (x) => x.averageLessonLength,
            )}
            apiQuery={apiQuery}
            icon={TimeIcon}
            caption="Avg. lessons length"
            formatter={formatMins}
            clarification="Average length of Live Tutoring lessons during the time frame selected."
          />
        )}
      />
      <LazyDashboardComponent
        intersecionDebugName="live: Avg. wait time"
        placeholder={<StatisticCardSkeleton />}
        render={(apiQuery, ref) => (
          <ApiStatisticCard
            ref={ref}
            provider={statisticsProvider(
              "/api/v1/new_stats/live/average_wait_time/",
              (x) => x.averageWaitTime,
            )}
            apiQuery={apiQuery}
            icon={HourglassIcon}
            caption="Avg. wait time"
            formatter={(x: unknown) => `${x} sec`}
            clarification="Average duration from tutor request submission to session start."
          />
        )}
      />
      <LazyDashboardComponent
        intersecionDebugName="live: Avg. tutor rating"
        placeholder={<StatisticCardSkeleton />}
        render={(apiQuery, ref) => (
          <ApiStatisticCard
            ref={ref}
            provider={statisticsProvider(
              "/api/v1/new_stats/live/average_rating/",
              (x) => x.averageRating,
            )}
            apiQuery={apiQuery}
            icon={AverageRatingIcon}
            caption="Avg. tutor rating"
            formatter={averageStudentRating}
            clarification="Average rating given by students to tutors after their Live Tutoring lessons."
          />
        )}
      />
    </StatisticsWrapper>
  );
};

export default DashboardLiveTutoringStatistic;

const AverageStudentRating = styled.div`
  align-items: center;
  display: flex;
`;

const OutOf = styled.span`
  font-size: 14px;
  font-weight: bold;
  margin: 0 10px;
  text-transform: uppercase;
`;
