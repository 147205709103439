import { omit } from "ramda";
import * as React from "react";

import Rating from "~/components/core/Rating/Rating";
import styled from "~/components/core/styled";
import Table, { Align, TableCell, TableRow } from "~/components/core/Table";
import WidgetCard from "~/components/core/WidgetCard";
import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import { useAuthenticatedFetch } from "~/utils/http";

import { createDashboardLazyWidget } from "../dashboardQueryState";

const COLUMNS = [
  { name: "Student", align: Align.left },
  { name: "Sessions", align: Align.center },
  { name: "Avg. Rating", align: Align.left }
];

const StyledTable = styled(Table)`
  width: 100%;
`;

const StyledWidgetCard = styled(WidgetCard)`
  flex: 1;
  margin-right: 18px;
`;

const StyledCell = styled(TableCell)`
  width: 150px;
`;

const DashboardPowerUsersSessions = createDashboardLazyWidget({
  displayName: "DashboardPowerUsersSessions",
  contentHeight: 380,
  caption: "Power Users - Sessions",
  clarification:
    "A power user is a student who has completed the most sessions for both Live Tutoring lessons and Writing Lab reviews during the time frame selected.",
  customWidgetCard: StyledWidgetCard,
  useRender: apiQuery => {
    const { data, loading, error } = useAuthenticatedFetch(
      "/api/v1/new_stats/combined/power_sessions/",
      omit(["scale"], apiQuery),
      true
    );
    return (
      <WidgetContentNew
        loading={loading}
        hasData={!!data?.length}
        error={error}
      >
        <StyledTable columns={COLUMNS} loading={false}>
          {data?.map(user => {
            return (
              <TableRow key={user.userId}>
                <TableCell align={Align.left}>{user.displayName}</TableCell>
                <TableCell align={Align.center}>{user.sessionsTaken}</TableCell>
                <StyledCell align={Align.left}>
                  <Rating rating={user.averageStudentRating} />
                </StyledCell>
              </TableRow>
            );
          })}
        </StyledTable>
      </WidgetContentNew>
    );
  }
});

export default DashboardPowerUsersSessions;
