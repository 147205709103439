import * as React from "react";

import { WidgetContentNew } from "~/components/core/WidgetCard/WidgetCardContent";
import { useAuthenticatedFetch } from "~/utils/http";

import { DashboardAreaChart } from "../AreaChart";
import { createDashboardLazyWidget } from "../dashboardQueryState";

const DashboardTotalSessionsChart = createDashboardLazyWidget({
  displayName: "DashboardTotalSessionsChart",
  contentHeight: 320,
  caption: "Total Sessions",
  clarification:
    "Total combined sessions completed for both Live Tutoring lessons and Writing Lab reviews.",
  useRender: apiQuery => {
    const { data, loading, error } = useAuthenticatedFetch(
      "/api/v1/charts/combined_sessions/",
      apiQuery,
      true
    );

    return (
      <WidgetContentNew
        loading={loading}
        hasData={!!data?.length}
        error={error}
      >
        <DashboardAreaChart apiQuery={apiQuery} data={data} label="Sessions" />
      </WidgetContentNew>
    );
  }
});

export default DashboardTotalSessionsChart;
