import dayjs from "dayjs";
import * as React from "react";

import NotebookIcon from "~/assets/icons/Notebook2.svg?sprite";
import StudentsIcon from "~/assets/icons/Students.svg?sprite";
import { useRequiredAuthContext } from "~/components/auth/AuthWrapper";
import StatisticCard from "~/components/core/StatisticCard";
import styled from "~/components/core/styled";
import DashboardGuideContext from "~/components/dashboard/DashboardGuide/DashboardGuideContext";
import { DATE_FORMAT_FULL_YEAR } from "~/constants/dates";
import { UMBRELLA_BILLING_MODE } from "~/declarations/models/UmbrellaAccount";
import { PERMISSION_CODENAME } from "~/declarations/models/User";
import { useAuthenticatedFetch } from "~/utils/http";
import useAccessPermission from "~/utils/useAccessPermission";

import DashboardContext from "../DashboardContext";
import { StatisticsWrapper } from "../styled";
import DashboardHoursRemaining from "./DashboardHoursRemaining";

const POST_PAY_CLARIFICATION = "You are on our Post-Paid Plan.";

const DashboardHeader: React.FC = (): JSX.Element => {
  const {
    selectedUmbrella: { umbrellaAccName }
  } = useRequiredAuthContext();
  const { umbrellaLimits } = React.useContext(DashboardContext);
  const { billingMode } = umbrellaLimits;
  const { headerRef } = React.useContext(DashboardGuideContext);

  const {
    data: studentsEnrolled,
    loading: studentsEnrolledLoading
  } = useAuthenticatedFetch(
    "/api/v1/new_stats/students_enrolled/",
    {
      umbrellaAccountId: umbrellaAccName
    },
    true
  );

  const {
    data: totalSessions,
    loading: totalSessionsLoading
  } = useAuthenticatedFetch(
    "/api/v1/new_stats/total_sessions_completed/",
    {
      umbrellaAccountId: umbrellaAccName
    },
    true
  );

  return (
    <Header>
      <DateClarificationInscription>
        {billingMode === UMBRELLA_BILLING_MODE.postpaid &&
          POST_PAY_CLARIFICATION}{" "}
        Here are your reports below as of{" "}
        {dayjs().format(DATE_FORMAT_FULL_YEAR)}
      </DateClarificationInscription>
      <StatisticsWrapper ref={headerRef}>
        <StyledStatisticCard
          granted={useAccessPermission(PERMISSION_CODENAME.students).granted}
          loading={studentsEnrolledLoading}
          avatar={{
            Icon: StudentsIcon,
            color: "primary"
          }}
          caption="Students enrolled"
          value={studentsEnrolled?.studentsEnrolled}
          link={{
            // text: "See students",
            // href: ROUTES.students
            text: ""
          }}
          clarification="Total number students in your school that are enrolled in the Pear Deck Tutor program."
        />
        <DashboardHoursRemaining />
        <StyledStatisticCard
          granted={useAccessPermission(PERMISSION_CODENAME.lessons).granted}
          loading={totalSessionsLoading}
          avatar={{
            Icon: NotebookIcon,
            color: "primary"
          }}
          caption="Total sessions completed"
          value={totalSessions?.totalSessionsCompleted}
          link={{
            // text: "See lessons",
            // href: ROUTES.lessons
            text: ""
          }}
          clarification="Total sessions are the sum of both live tutoring lessons and Writing Lab reviews completed."
        />
      </StatisticsWrapper>
    </Header>
  );
};

export default DashboardHeader;

const Header = styled.header`
  background-color: ${({ theme }): string => theme.palette.common.white};
  margin: 0 -${({ theme }): number => theme.layouts.account.content.padding}px;
  padding: 0 30px;

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.sm}px) {
    margin: 0;
    padding: 0;
  }
`;

const DateClarificationInscription = styled.section`
  color: ${({ theme }): string => theme.palette.grey[800]};
  margin-top: -12px;

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.sm}px) {
    margin-top: -5px;
    margin-left: 10px;
  }
`;

const StyledStatisticCard = styled(StatisticCard)`
  background-color: ${({ theme }): string => theme.palette.grey[50]};

  @media (max-width: ${({ theme }): number => theme.breakpoints.values.md}px) {
    width: 100%;
  }
`;
